.header {
  border: 0px;
}

/*
@font-face{

    font-display: swap;
}
*/

div .pure-modal-backdrop {
  z-index: 10003;
}

div .pure-modal-backdrop .pure-modal {
  max-width: 800px;
}

.ReactCollapse--collapse {
  box-sizing: border-box;
}

div .pure-modal .close {
  color: #d01c15;
  font-size: 50px;
  background: none;
}

.input-block .input-error {
  border-color: #d01c15 !important;
}

span.error {
  display: block;
  margin-top: 0.3rem;
  color: #d01c15;
  font-size: 0.9rem;
}

.input-block.reg-num input {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 3C0 1.34315 1.34315 0 3 0H40V40H3C1.34315 40 0 38.6569 0 37V3Z' fill='%233B74CE'/%3e%3cpath d='M15.2235 15.06C12.4375 15.046 10.0155 17.09 10.0155 20.1C10.0155 22.886 12.3115 25.14 15.1395 25.14C16.7215 25.14 18.1495 24.58 19.2275 23.642V19.764H15.8115V20.688H17.8555V22.914C17.1275 23.502 16.2035 23.866 15.1395 23.866C13.0815 23.866 11.4855 22.186 11.4855 20.128C11.4855 17.958 13.1795 16.376 15.2235 16.376C16.2175 16.376 17.1415 16.74 17.8835 17.398L18.8215 16.418C17.8275 15.55 16.5535 15.074 15.2235 15.06ZM21.9189 15.2V25H26.9169C29.4649 25 30.2769 23.67 30.2769 22.368C30.2769 21.276 29.7309 20.198 28.0929 19.862C29.1709 19.526 29.8009 18.798 29.8009 17.65C29.8009 16.026 28.6109 15.2 26.5949 15.2H21.9189ZM23.3469 16.362H26.2029C27.5609 16.362 28.3169 16.894 28.3169 17.93C28.3169 18.952 27.5329 19.484 26.2029 19.484H23.3469V16.362ZM23.3469 20.45H26.5809C28.2749 20.45 28.8069 21.29 28.8069 22.144C28.8069 22.998 28.2749 23.824 26.5809 23.824H23.3469V20.45Z' fill='white'/%3e%3c/svg%3e ");
  background-repeat: no-repeat;
  background-position: -1px;
  padding-left: 48px !important;
  background-size: 40px 42px;
}

.pure-modal__paymentCyberFrame {
  height: auto;
  max-width: 700px !important;
  width: 100% !important;
}

.react-datepicker__navigation {
  width: 10% !important;
  max-width: auto !important;
  margin-top: 0px !important;
}

.react-datepicker__day--selected {
  background-color: #ce171f;
}

.react-datepicker__day--selected:hover {
  background-color: #ce171f;
}

/* Part of Tailwind */

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.font-medium {
  font-weight: 500;
}

.text-base {
  font-size: 16px;
  line-height: 28px;
}

.text-medium {
  font-size: 16px;
  line-height: 24px;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.pe-2 {
  padding-inline-end: 8px;
}

.w-full {
  width: 100%;
}

.overflow-x-auto {
  overflow-x: auto;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[1139px\] {
  max-width: 1139px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.items-center {
  align-items: center;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.w-5 {
  width: 20px;
}

.h-5 {
  height: 20px;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 4px;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.z-10 {
  z-index: 10;
}

.top-20 {
  top: 80px;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.absolute {
  position: absolute;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p-6 {
  padding: 24px;
}

.gap-8 {
  gap: 32px;
}

.gap-10 {
  gap: 40px;
}

.gap-16 {
  gap: 64px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-4 {
  gap: 16px;
}

.flex-col {
  flex-direction: column;
}

.min-w-36 {
  min-width: 144px;
}

.max-h-400 {
  max-height: 400px;
}

.w-fit {
  width: fit-content;
}

.min-w-60 {
  min-width: 240px;
}

.w-72 {
  width: 288px;
}

.tracking-wide {
  letter-spacing: 0.4px;
}

.uppercase {
  text-transform: uppercase;
}

.text-sm {
  font-size: 14px;
  line-height: 20px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.grid {
  display: grid;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.py-10 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.border-t {
  border-top-width: 1px;
  border-top-style: solid;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-b-2 {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.transition-enter {
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.transition-enter-from {
  opacity: 0;
  transform: translateY(-4px);
}

.transition-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.transition-leave {
  transition: opacity 150ms ease-in, transform 150ms ease-in;
}

.transition-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.transition-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.min-h-20 {
  min-height: 80px;
}

.ms-auto {
  margin-inline-start: auto;
}

.top-0 {
  top: 0;
}

.sticky {
  position: sticky;
}

.justify-end {
  justify-content: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-grow {
  flex-grow: 1;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.p-2 {
  padding: 8px;
}

.rounded-lg {
  border-radius: 8px;
}

.text-regal-red {
  --tw-text-opacity: 1;
  color: rgb(206 24 31 / var(--tw-text-opacity));
}

.w-8 {
  width: 32px;
}

.h-8 {
  height: 32px;
}

.inset-0 {
  inset: 0;
}

.fixed {
  position: fixed;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.backdrop-blur-sm {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.bg-slate-900\/25 {
  background-color: rgb(15 23 42 / 0.25);
}

.z-40 {
  z-index: 40;
}

.overflow-y-auto {
  overflow-y: auto;
}

.h-full {
  height: 100%;
}

.z-50 {
  z-index: 50;
}

.right-0 {
  right: 0;
}

.popover-panel {
  position: fixed;
  right: 0;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  height: 100%;
  z-index: 50;
  width: min(320px, calc(100vw - 40px));
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  box-shadow: 0 0 0 1px var(--tw-ring-color);
}

.ring-black\/10 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / 0.1);
}

.transition {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.top-5 {
  top: 20px;
}

.h-10 {
  height: 40px; /* 10 * 4px */
}

.w-10 {
  width: 40px;
}

.justify-center {
  justify-content: center;
}

.right-4 {
  right: 16px;
}

.divide-slate-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.1);
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-top-style: solid;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-\[25px\] {
  padding-left: 25px;
  padding-right: 25px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-11 {
  padding-top: 44px;
  padding-bottom: 44px;
}

.px-7 {
  padding-left: 28px;
  padding-right: 28px;
}

.max-w-\[362px\] {
  max-width: 362px;
}

.flex-row {
  flex-direction: row;
}

.-my-2 {
  margin-top: -8px;
  margin-bottom: -8px;
}

.items-start {
  align-items: flex-start;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: 8px;
}

.block {
  display: block;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.font-semibold {
  font-weight: 600;
}

.h-6 {
  height: 24px;
}

.w-6 {
  width: 24px;
}

.right-5 {
  right: 20px;
}

.top-6 {
  top: 24px;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.ease-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.duration-200 {
  transition-duration: 200ms;
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.w-full {
  width: 100%;
}

.w-\[302px\] {
  width: 302px;
}

.text-start {
  text-align: start;
}

.text-lg {
  font-size: 18px;
  line-height: 28px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-9 {
  padding-bottom: 36px;
}

.text-xs {
  font-size: 12px;
  line-height: 16px;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-cool-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-cool-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.pt-2 {
  padding-top: 8px;
}

.pt-3 {
  padding-top: 12px;
}

hr.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
  border-top-width: 0px;
}

.mt-5 {
  margin-top: 20px;
}

.gap-y-3 {
  row-gap: 12px;
}

.gap-x-10 {
  column-gap: 40px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mb-5 {
  margin-bottom: 20px;
}

.scrollbar {
  --scrollbar-thumb-radius: 9999px;
  --scrollbar-track-radius: 9999px;
  --scrollbar-thumb: #ce181f;
  scrollbar-width: auto;
  scrollbar-color: var(--scrollbar-thumb);
}
.scrollbar::-webkit-scrollbar {
  display: block;
  width: 4px;
  height: 4px;
}
.scrollbar::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}
.scrollbar::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}
.scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

.text-center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.gap-2 {
  gap: 8px;
}

.gap-5 {
  gap: 20px;
}

.gap-\[10px\] {
  gap: 10px;
}

@media (min-width: 640px) {
  .sm\:text-base {
    font-size: 16px;
    line-height: 24px;
  }
  .sm\:text-start {
    text-align: start;
  }

  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:max-w-\[210px\] {
    max-width: 210px;
  }
  .sm\:px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 768px) {
  .md\:max-w-\[712px\] {
    max-width: 712px;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:gap-24 {
    gap: 96px;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
  .lg\:ml-6 {
    margin-left: 24px;
  }
  .lg\:px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .lg\:mx-24 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .lg\:px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:max-w-\[984px\] {
    max-width: 984px;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:gap-0 {
    gap: 0px;
  }
  .lg\:gap-3 {
    gap: 12px;
  }
  .lg\:min-w-0 {
    min-width: 0px;
  }
}

@media (min-width: 1280px) {
  .xl\:mx-56 {
    margin-left: 224px;
    margin-right: 224px;
  }
  .xl\:max-w-\[1350px\] {
    max-width: 1350px;
  }
  .xl\:max-w-\[1388px\] {
    max-width: 1388px;
  }
  .xl\:py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .xl\:py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .xl\:grid-cols-\[1fr_3fr\] {
    grid-template-columns: 1fr 3fr;
  }

  .xl\:gap-20 {
    gap: 80px;
  }

  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:gap-24 {
    gap: 96px;
  }
}

.leading-6 {
  line-height: 24px;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[18px\] {
  line-height: 18px;
}
