.svgButton {
    padding: 8px 8px;
    width: 120px !important;
    color: #ce181f;
    background-color: transparent;
    align-items: center;
    border: 1px solid #ce181f;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    font-size: 13px !important;
    height: 39px;
    justify-content: start;
    transition: all .3s ease;
}

.buttonSpan {
    margin-left: 3px;
    font-size: 13px !important;
}

.buttonChat {
    width: 100%;
    justify-content: center;
}