.chatBottom {
    display: flex;
    background: #eceff1;
    border-radius: 3px;

    textarea {
        height: auto !important;
        border: none !important;
        background: #eceff1 !important;
    }
}