.chat {
	$parent: &;

	display: none;
	min-width: 321px;
	max-width: 321px;
	background-color: #ffffff;
	border-radius: 10px 10px 0 10px;
	border: 1px solid #e6e6e6;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
	z-index: 9999;

	@media (max-width: 767px) {
		position: fixed;
		min-width: 100vw;
		max-width: 100%;
		bottom: 0;
		height: 100vh;
		height: 100%;
		//min-height: 100vh;
		//max-height: 100vh;
		//height: -webkit-fill-available;
		top: 0;
		bottom: auto;
		right: 0px;
	}


	&__top {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		gap: 15px;
		border-bottom: 1px solid #e6e6e6;
		padding: 10px 15px;
	}

	&__title {
		display: flex;
		align-items: center;
		font-size: 15px;

		svg {
			fill: #ce181f;
			margin-right: 10px;
		}
	}

	&__close {
		cursor: pointer;

		@media (min-width: 1024px) {
			&:hover svg {
				fill: rgba(#ce181f, 0.8);
			}
		}

		svg {
			fill: #ce181f;
			transition: fill 0.3s ease;
		}
	}

	&__main {
		background-color: #fff;
		padding: 18px 14px;
		padding-right: 5px;

		@media (max-width: 767px) {
			margin-bottom: 15px;
			//height: calc(100% - 150px);
			height: calc(100% - 152px);
		}
	}

	&__scroll {
		height: 493px;
		max-height: 45vh;
		overflow: hidden;

		@media (max-width: 767px) {
			//height:  calc(100vh - 152px);
			//max-height: calc(100vh - 100px);
			//max-height:100vh;
			//height: auto;
			max-height: calc(100% - 152px);
			height: 100%;
			min-height: 100%;
		}

		.mCSB_inside>.mCSB_container {
			margin-right: 15px;
		}

		.mCSB_scrollTools {
			right: -6px;
		}

		.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
			width: 4px;
			background-color: #ce181f;
			border-radius: 5px;
		}

		.mCSB_scrollTools .mCSB_draggerRail {
			background-color: #e6e6e6;
		}
	}

	&__started {
		font-size: 13px;
		text-align: center;
		margin-bottom: 20px;
	}

	&__started-date {
		color: #9da4b0;
		margin-top: 2px;
	}

	&__message {
		max-width: 195px;
		margin-left: auto;
		border-radius: 10px 10px 0 10px;
		padding: 9px 14px;
		background: #f5f5f5;

		+#{$parent}__message {
			margin-top: 11px;
		}

		&--user {
			display: flex;
			margin-left: 0;
			padding: 0;
			background: transparent;
			max-width: calc(100% - 23px);

			.chat__message-wrapper {
				border-radius: 10px 10px 10px 0;
				padding: 9px 14px;
				background: #2e7af1;
				width: 203px;
			}

			#{$parent} {
				&__message-bubble {
					padding: 0;
					font-weight: 500;
					font-size: 13px;
					line-height: 146%;
					color: #fff;
					border: none;
					margin-bottom: 6px;
				}

				&__message-info {
					text-align: left;
					font-weight: 500;
					font-size: 11px;
					line-height: 136%;
					color: #90bdd7;
				}

				&__author {
					font-weight: 500;
					font-size: 11px;
					line-height: 136%;
					color: #90bdd7;
				}

				&__date {
					font-weight: 500;
					font-size: 11px;
					line-height: 136%;
					color: #90bdd7;
				}
			}
		}

		&__label {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin-top: 20px;
			margin-bottom: 20px;
			padding-left: 6px;

			&__text {
				width: fit-content;
				padding: 0px 10px;
				font-weight: 500;
				font-size: 12px;
				line-height: 125%;
				text-align: center;
				color: #9DA4B0;
				white-space: nowrap;
			}

			&__line {
				position: relative;
				display: flex;
				height: 1px;
				width: 100%;
				background: #D9D9D9;
			}
		}

		&__file {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-flow: column nowrap;

			&__item {
				position: relative;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 6px;

				svg {
					margin-right: 6px;
				}

				&__text {
					font-weight: 500;
					font-size: 12px;
					line-height: 100%;
					color: #ce181f;
				}
			}
		}

		&__robot {
			position: relative;
			display: flex;
			flex-flow: row wrap;
			width: 100%;
			margin-top: 15px;
			margin-bottom: 15px;

			&__item {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
				padding: 8px 5px;
				width: fit-content;
				background: #dceaff;
				margin-right: 10px;
				margin-bottom: 10px;
				font-weight: 500;
				font-size: 13px;
				line-height: 146%;
				text-align: center;
				color: #4C4C4C;

				&:last-child {
					margin-right: 0;
				}
			}
		}


	}

	&__message-bubble {
		font-weight: 500;
		font-size: 13px;
		line-height: 146%;
		color: #4c4c4c;
		margin-bottom: 6px;
	}

	&__message-info {
		font-weight: 500;
		font-size: 11px;
		line-height: 136%;
		text-align: right;
		margin-top: 2px;
		display: flex;
		justify-content: space-between;
	}

	&__author {
		font-weight: 500;
		font-size: 11px;
		line-height: 136%;
		color: #3B74CE;
	}

	&__date {
		color: #9da4b0;
		font-weight: 500;
		font-size: 11px;
		line-height: 136%;
	}

	&__avatar {
		min-width: 40px;
		max-width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 5px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__bottom {
		padding: 1px 15px 15px;
		padding-bottom: 14px;

		.input-block {
			position: relative;

			label:first-child {
				width: 100%;
			}

			@media (max-width: 767px) {

				select:focus,
				textarea:focus,
				input:focus {
					font-size: 16px;
				}
			}
		}
	}

	.form-block .input-block {
		input {
			margin-top: 0;
			background: #eceff1;
			border-radius: 5px;
			border: none;

			&::placeholder {
				font-weight: 500;
				font-size: 13px;
				color: 9DA4B0;
			}
		}

		label:not(.checkbox-container).chat {

			&__file,
			&__send-btn {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 34px;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				@media (min-width: 1024px) {
					&:hover {
						opacity: 0.8;
					}
				}

				input {
					display: none;
				}
			}

			&__file {
				right: 34px;

				svg {
					fill: #b2b9bc;
				}
			}

			&__send-btn {
				right: 0;

				svg {
					fill: #ce181f;
				}
			}
		}
	}
}