.contact-us {
  position: fixed;
  bottom: 10px;
  right: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 340px;
  box-sizing: border-box;
  z-index: 11;
  @media (max-width: 767px) {
    bottom: 10px;
    right: 7px;
    z-index: 10004;
  }

  &__toggle-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 142px;
    height: auto;
    min-height: 62px;
    font-size: 15px;
    margin-top: 15px;
    border-radius: 15px;
    background: #ce181f;
    padding: 15px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    span {
      text-align: left;
    }
  }

  &__toggle-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    margin-top: 2px;
  }

  &__wrapper {
    display: none;
    position: absolute;
    bottom: 77px;
    right: 0px;
    background: #fff;
    justify-content: flex-start;
    align-items: flex-start;
    width: 320px;
    border-radius: 20px 20px 0px 20px;
    border: 1px solid var(--90-grey, #e6e6e6);
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__start-mess {
    position: relative;
    display: flex;
    width: 100%;
    padding: 30px 21px 0px 20px;
    box-sizing: border-box;
    .chat__message {
      &.chat__message--user {
        max-width: 100%;
      }
    }
    .chat__avatar {
      border: 1px solid #e6e6e6;
      margin-right: 7px;
    }
    .chat__message-wrapper {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .chat__message-bubble {
      border-radius: 15px 15px 15px 0px;
      background: #3b74ce;
    }
    .chat__author {
      color: var(--Font-Grey, #9da4b0);
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__links {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    box-sizing: border-box;
    padding: 18px 21px 30px 20px;
  }
  &__link {
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    width: calc(50% - 6px);
    padding: 10px 10px;
    font-size: 13px;
    height: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    transition: background-color 0.3s ease;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    border-radius: 15px;
    svg {
      margin-right: 4px;
    }
    span {
      white-space: nowrap;
    }
    &--blue {
      border: 1px solid #3b74ce;
      color: #3b74ce;
      &:hover {
        background: #3b74ce;
        color: #fff;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }

    &--green {
      border: 1px solid #06a411;
      color: #06a411;
      &:hover {
        background-color: #0b8314;
        color: #fff;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }

    &--light-blue {
      border: 1px solid #00abff;
      color: #00abff;
      &:hover {
        background-color: #00abff;
        color: #fff;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }

    &--red {
      border: 1px solid #d23238;
      color: #d23238;
      &:hover {
        background-color: #d23238;
        color: #fff;
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &__top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 41px 16px 20px;
    border-bottom: 1px solid #e6e6e6;
    &__title {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      color: #2c2c2c;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      svg {
        margin-right: 7px;
      }
    }
    &__close {
      position: absolute;
      right: 10px;
      top: 7px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        transition: all 0.3s;
        background: rgba(206, 24, 31, 0.2);
      }
    }
  }
  &__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // &__chat {
  // 	position: absolute;
  // 	bottom: 80px;
  // 	right: 0;
  // }
}
