.VirtualAssistant {
    position: fixed;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 340px;
    box-sizing: border-box;
    z-index: 11;
}