.buttonsRow {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 2px;
}

.loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}