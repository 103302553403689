.eventCaption {
  width: 100%;
  //position: fixed;
  top: 62px;
  left: 0;
  //z-index: 20;
  box-sizing: border-box;
  background-color: #3b74ce;
  //margin-top: 18px;

  @media (max-width: 576px) {
    margin-top: 0px;
  }

  @media (max-width: 1200px) {
    margin-top: 50px;
  }
}

.eventWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;

  //padding-right: 25px;
  // background-color: #3B74CE;
  @media (max-width: 576px) {
    height: 110px;
  }
}

.banner {
  width: 100%;
  height: 40px;
  cursor: pointer;

  @media (max-width: 576px) {
    height: 110px;
  }
}

.pic {
  object-fit: contain;
  object-position: center center;
  box-sizing: content-box;
}

.img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
