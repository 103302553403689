.accordion__tabs-title-react {
  height: 31px;
  line-height: 31px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  background-color: #e6e6e6;
  border-bottom: 1px solid #bfbfbf;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  border-radius: 5px 5px 0 0;
  color: #000;
}

.accordion__tabs-title-react:not(:last-child) {
  border-right: 1px solid #bfbfbf;
}

.modal-map-contacts {
  padding-left: 13px;
  padding-right: 13px;
}

.modal-map-contacts .content .contacts .accordion > div {
  min-width: 380px;
}

@media screen and (max-width: 650px) {
  .modal-map-contacts .content .contacts .accordion > div {
    min-width: auto;
  }
}

.main-menu__container {
  z-index: 10001;
}
