.error__page {

    &__inner {
        max-width: 600px;
        padding: 0 20px;
        padding-top: 200px;
        width: 100%;
        box-sizing: border-box;
        margin: 0 auto;
    }

    &__image {

        img {
            width: 100%;
            display: block;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;

        h3 {
            color: red;
        }
    }

    &__form {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
    }
}