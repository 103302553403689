@media (min-width: 1024px) {
  .header__nav > li:hover > .header__submenu {
    opacity: 1;
    pointer-events: auto;
  }
}

.header__nav > li + li {
  margin-left: 30px;
}

@media (max-width: 1640px) {
  .header__nav {
    display: none;
  }
}

@media (min-width: 1600px) {
  .header__nav {
    display: flex !important;
  }
}

.header-mobile__menu-link span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4c4c4c;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.header-mobile__submenu-list-wrapper > a {
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;

  border-bottom: 1px solid #ce181f;
  padding-bottom: 14px;
  margin-bottom: 15px;
}

.header__submenu-group > a {
  display: block;
  padding-bottom: 14px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid #ce181f;
  color: #2c2c2c;
}

.ccc-notify-button {
  display: inline !important;
}

.header__submenu {
  width: 100%;
  padding: 25px 20px 45px 321px;
  background-color: #f7f7f7;
  position: absolute;
  top: 80px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.header__submenu::before {
  position: absolute;
  top: -30px;
  height: 30px;
  left: 0;
  width: 100%;
  content: '';
  display: block;
  background: transparent;
}

.header__logo {
  margin: 0;
}

@media (max-width: 1200px) {
  .header__logo {
    width: 186px;
    height: 45px;
  }
}
