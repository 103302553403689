.chatBottom {
    display: flex;
    background: #eceff1;
    border-radius: 3px;


    textarea {
        height: auto !important;
        border: none !important;
        background: #eceff1 !important;
    }
}

.chatLink {
    color: #ce181f;
    font-weight: 700;
    //color: black;
    cursor: pointer;
    text-decoration: underline;
}