.contacts-caption {
  width: 100%;
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 20;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    display: none;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding-right: 25px;
    background-color: #ce171f;
  }

  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    background-color: #b3181f;
    cursor: pointer;

    svg {
      fill: #fff;
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    span {
      padding-right: 10px;
      font-size: 15px;
      color: #fefefe;

      @media (max-width: 380px) {
        font-size: 13px;
      }
    }
  }

  &__socials-item {
    display: flex;
    align-items: center;
    height: 24px;
    padding-left: 12px;
    margin-left: 12px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background-color: #d8383f;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &__toggle {
    display: none;
    width: 100%;
    height: calc(100vh - 112px);
    padding: 13px 17px;
    background-color: #fff;
    position: fixed;
    top: 112px;
    left: 0;
    overflow: auto;
    box-sizing: border-box;
  }

  &__block {
    + .contacts-caption__block {
      margin-top: 17px;
      padding-top: 8px;
      border-top: 1px solid #e6e6e6;
    }

    &:last-child {
      padding-bottom: 70px;
    }
  }

  &__office {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #2c2c2c;

    svg {
      fill: #b2b9bc;
    }
  }

  &__list {
    margin-top: 9px;

    li {
      display: flex;
      padding-left: 10px;
      font-size: 13px;
      line-height: 17px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        background-color: #ce171f;
        position: absolute;
        top: 8px;
        left: 0;
      }

      + li {
        margin-top: 8px;
      }

      span {
        max-width: 80px;
        width: 100%;
      }

      a {
        color: #2c2c2c;
        text-decoration: underline;
      }
    }
  }
}
